import {and, collection, getDocs, getFirestore, onSnapshot, or, query, QuerySnapshot, where} from "firebase/firestore";
import app from '../firebaseConfig';
import {EventDepartment, IFirestoreTimeOffRequest, ITimeOffRequest, RequestStatus} from "./interfaces";
import {getEmployeeDetailsByRef} from "./getEmployeeDetailsByRef";


const db = getFirestore(app);

/**
 * Fetches events within a specified date range for a given department and sets up real-time updates.
 *
 * @param {Date} start - The start date of the range.
 * @param {Date} end - The end date of the range.
 * @param {EventDepartment} department - The department to filter events by.
 * @param {(events: IEvent[]) => void} onEventsUpdate - Callback function to handle updates to the list of events.
 * @returns {Promise<() => void>} - A function to unsubscribe from real-time updates when called.
 */
export async function fetchTimeOffForRange(
  start: Date,
  end: Date,
  department: EventDepartment,
  onEventsUpdate: (events: ITimeOffRequest[]) => void
): Promise<() => void> {
  // Convert dates to 'YYYY-MM-DD' format for querying Firestore
  const startOfRangeString = start.toISOString().split("T")[0];
  const endOfRangeString = end.toISOString().split("T")[0];

  // Reference the events collection in Firestore
  const eventsCollectionRef = collection(db, "timeOffRequests");

  // Create a query to fetch events where:
  // (startDate is within the range AND department matches) OR
  // (endDate is within the range AND department matches)
  const q = query(
    eventsCollectionRef,
    or(
      and(
        where("startDate", ">=", startOfRangeString),
        where("startDate", "<=", endOfRangeString),
        where("status", "==", "approved" as RequestStatus)
      ),
      and(
        where("endDate", ">=", startOfRangeString),
        where("endDate", "<=", endOfRangeString),
        where("status", "==", "approved" as RequestStatus)
      ),
      and(
        where("startDate", "<", startOfRangeString),
        where("endDate", ">", endOfRangeString),
        where("status", "==", "approved" as RequestStatus)
      )
    )
  );

  /**
   * Processes a Firestore snapshot by converting documents to events and passing them to the update callback.
   *
   * @param {QuerySnapshot} snapshot - Firestore snapshot of documents matching the query.
   */
  const processSnapshot = async (snapshot: QuerySnapshot) => {
    // Convert Firestore documents to your IEvent objects
    const events = await convertTimeOff(snapshot.docs);
    // Filter the events by department
    const filteredEvents = events.filter((e) => e.department === department);
    // Trigger the callback to update the events
    onEventsUpdate(filteredEvents);
  };

  // Fetch initial data when the function is called and process it
  const initialSnapshot = await getDocs(q);
  await processSnapshot(initialSnapshot);

  // Set up a real-time listener for changes to the queried events and process updates
  // Return the unsubscribe function to allow the caller to stop listening for updates
  return onSnapshot(q, processSnapshot);
}


/**
 * Converts Firestore documents to IEvent objects, filtering by department.
 *
 * This function processes all documents in parallel to improve performance.
 *
 * @param {any[]} docs - The Firestore documents to convert.
 * @returns {Promise<IEvent[]>} An array of IEvent objects.
 */
async function convertTimeOff(docs: any[]): Promise<ITimeOffRequest[]> {
  // Map over the documents to create an array of Promises for event conversions
  const eventPromises: Promise<ITimeOffRequest | null>[] = docs.map(async (doc) => {
    try {
      const docData = doc.data() as IFirestoreTimeOffRequest;

      // Start fetching traveler and crew leader data concurrently
      const employee = await getEmployeeDetailsByRef(docData.employee);


      let department: EventDepartment | "Other" = "Other";
      switch (employee?.departmentString) {
        case "Customer Support":
          department = "Customer Support";
          break;
        case "Installation":
          department = "Installation";
          break;
      }
      // Add an extra day to the end date
      const endDate = new Date(docData.endDate);
      endDate.setDate(endDate.getDate() + 1);

      // Set the event colors
      const backgroundColor = "#9ae194";
      const borderColor = backgroundColor;
      const textColor = "#000";

      return {
        ...docData,
        endDate: endDate.toISOString().split("T")[0], // Convert back to 'YYYY-MM-DD' format
        employeeName: "🏖️" + (employee?.preferredName || "Error loading request"),
        department: department,
        backgroundColor,
        borderColor,
        textColor,
      };

    } catch (error) {
      // Log the error and continue processing other documents
      console.error(`Error processing event ${doc.id}:`, error);
      return null; // Skip this event in case of an error
    }
  });

  // Wait for all event conversion Promises to resolve
  const events = await Promise.all(eventPromises);

  // Filter out any null results (events that were skipped)
  return events.filter((event): event is ITimeOffRequest => event !== null);
}
