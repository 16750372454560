import React, {useEffect, useState} from 'react';
import {fetchUnscheduledTravelers} from '../data/fetchTravelers';
import {ITraveler, Department} from '../data/interfaces';
import {Card} from 'react-bootstrap';

interface UnscheduledTravelersProps {
  department: Department;
  onTravelerClick: (travelerId: string) => void;
}

/**
 * Component to display unscheduled travelers.
 */
const UnscheduledTravelers: React.FC<UnscheduledTravelersProps> = ({ department, onTravelerClick }) => {

  const [travelers, setTravelers] = useState<ITraveler[]>([]);

  /**
   * Fetch travelers when the component mounts or when the department changes.
   */
  useEffect(() => {
    // Define the data change handler
    const handleDataChange = (unscheduledTravelersData: ITraveler[]) => {
      // Filter travelers where 'events' is null, undefined, or an empty array
      // and 'Work Order Type' is 'In-House Work'
      const filteredTravelers = unscheduledTravelersData.filter((traveler) => {
        return traveler && (!traveler.events || traveler.events.length === 0) &&
          traveler.workOrderType !== 'In-House Work';
      });
      setTravelers(filteredTravelers);
    };

    // Define the error handler
    const handleError = (error: Error) => {
      console.error('Error fetching unscheduled travelers:', error);
    };

    // Subscribe to unscheduled travelers
    const unsubscribe = fetchUnscheduledTravelers(department, handleDataChange, handleError);

    // Cleanup function to unsubscribe when the component unmounts or when department changes
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [department]);

  return (
    <div>
      {travelers.length > 0 ? (
        travelers.map((traveler) => (
          <Card key={traveler.id} className="m-3" onClick={() => onTravelerClick(traveler.id)}>
            <Card.Body>{traveler.displayName}</Card.Body>
          </Card>
        ))
      ) : (
        <p>No unscheduled travelers for {department}.</p>
      )}
    </div>
  );
};

export default UnscheduledTravelers;
